// DragDrop

<template>
    <v-container fluid ma-0 pa-0>
        <v-row no-gutters>
        <v-col cols="12" sm="3" class="pt-0">
        <v-card tile class="grey darken-3 overflow-hidden">
            <v-app-bar absolute dark dense color="blue-grey darken-2"
                scroll-target="#playground" elevate-on-scroll
            >
                <v-toolbar-title>Fields</v-toolbar-title>
            </v-app-bar>
            <v-sheet id="playground" class="grey darken-3 overflow-y-auto" max-height="700">
                <v-container fluid style="height: 1500px;padding-top: 50px;"
                    @drop.stop="drop($event, 1)" @dragover.prevent
                >
                </v-container>
            </v-sheet>
        </v-card>
        </v-col>
        <v-divider />
        <v-col cols="12" sm="9" class="pl-0 pt-0">
        <v-card tile class="grey darken-3">
            <v-app-bar absolute dark dense flat color="blue-grey darken-2">
                <v-toolbar-title>Formular</v-toolbar-title>
				<v-spacer/>
				<v-btn small class="ml-3" color="blue-grey">Config</v-btn>
            </v-app-bar>
            <v-sheet class="grey darken-3 ma-0 pa-1" height="700">
                <v-container fluid style="height: 500px;padding-top: 50px;"
				    @drop.stop="drop($event, 2)" @dragover.prevent
                >
                </v-container>
            </v-sheet>
        </v-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    name: 'DragDrop',
    components: {},
    props: {
        ctx: {
            type: Object, default: () => ({})
        },
    },
    data: () => ({
        msgError: '',
        isDirty: false,
        loading: false,
        items: [],
    }),
    computed: {
        ...mapGetters({
            cfglists: 'configs/getCfgLists',
        }),
    },
    methods: {
        fetchData() {
            this.loading = true
            let param = this.ctx
            let self = this
            this.$store.dispatch('configs/getCfgLists', param)
            .then((response) => {
                console.log(this.cfglists)
                this.items = JSON.parse(JSON.stringify(this.cfglists));
                this.items[0].pos = 99
                self.loading = false
            })
            .catch((error) => {
                console.log(error)
                self.loading = false
            });
        },
		sortItems() {
			this.items.sort((a, b) => {
				return (a.pos || b.pos) ? a.pos - b.pos : (a.column_name < b.column_name) ? -1 : 1
        	})
			this.items.forEach((e, index) => {
				e.pos = index * 10;
			})
        },
    },
    mounted() {
        console.log('mounted: ' + JSON.stringify(this.ctx))
		this.fetchData()
    },
    beforeDestroy() {
        console.log('destroying: ' + JSON.stringify(this.ctx))
        let param = {
            ctx: this.ctx,
            cfglists: this.items,
        }
        this.$emit('dying', param)
    },
    watch: {},
}
</script>

<style scoped>
</style>